.analysis-filter-container {
    padding: 15px 10px 20px 10px;
    margin-bottom: 15px;
}

.filter-input-heading {
    font-family: "Inter";
    font-size: 12px;
    line-height: 15px;
    color: #6D6E71;
    padding-bottom: 5px;
}
.filter-preference-heading {
    font-family: "Mulish";
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    color: #727272;
    padding: 10px;
    background: white;
    font-weight: 700;
}
.filter-modal-input {
    width: 100%;
    padding: 4px 6px;
    font-size: 12px;
    line-height: 22px;
    font-family: "Muli";
    outline: none;
    display: flex;
    border: 1px solid #E0E0E0;
    //box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
}
.modal-input {
    width: 98%;
    padding: 1% 2%;
    padding-right: 3%;
    padding-left: 0.5rem;
    border: 1px solid #dddddd;
    font-size: 12px;
    line-height: 22px;
    font-family: "Inter";
    outline: none;
    display: flex;
}
.filter-modal-autocomplete-container {
    max-width: 100%;
    border-left: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
    border-bottom: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.filter-modal-autocomplete-container .custom-scrollbar-checkbox {
    padding-right: 0;
}
.filter-modal-input-suggestion {
    width: 100%;
    padding: 1% 3%;
    border: none;
    font-size: 12px;
    line-height: 22px;
    text-align: left;
    font-family: "Inter";
    outline: none;
    background: white;
    color: #626262;
}
.filter-modal-input-suggestion:hover {
    background: #fff4cd;
}
.filter-actions-img {
    width: 15px;
    height: 15px;
}
.filter-actions-button {
    background: linear-gradient(180deg, #FFEF5A 0%, #FBCE2F 100%);
    box-shadow: 2px 4px 10px rgba(251, 206, 47, 0.3);
    border-radius: 7px;
    margin-left: 5px;
    margin-right: 5px;
    width: 30px;
    height: 30px;
    display: inline-block;
}
.filter-operator-heading {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 5px;
    text-align: left;
    position: relative;
    top: -7px;
    left: -5px;
    color: #727272;
}
.filter-add-button {
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 500;
    line-height: 10px;
    text-align: left;
    color: #88305F;
    position: relative;
    top: -5px;
    right: -5px;
    margin-bottom: 10px;
}
.filter-add-text {
    text-decoration: underline;
}
.filter-contains{
    font-family: "Inter";
    font-size: 12px;
    line-height: 15px;
    text-align: left;
}
