.reports-table,
.reports-header-container {
  background-color: white;
  height: 44px;
  max-height: 44px;
  box-shadow: 1px 2px 8px rgb(0 0 0 / 10%);
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #808080;
  padding-left: 0.4%;
}
.reports-table .data-info {
  text-align: center;
  height: 44px;
  cursor: default;
  text-align: left;
  display: inline-flex;
  padding: 1%;
}

.reports-header-container {
  cursor: default;
  color: #2b2b2b;
  font-size: 14px;
  line-height: 22px;
}

.report-header{
  height: 44px;
  padding-top: 0.8%; 
}
.report-header:hover{
  background: #C4C4C4;
}