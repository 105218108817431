.generic-web-menu {
    margin-top: 5px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.generic-web-menu-item {
    text-align: center;
    width: 48px;
    padding-top: 0.6%;
    cursor: pointer;
    color: #818181;
    padding-bottom: 10px;
    font-size: 12px;
    justify-content: center;
}

.web-menu-container {
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    height: 48px;
}

.detail-container {
    position: absolute;
    background-color: #fff;
    font-size: 13px;
    transform: translate3d(-20px, 20px, 0);
    right: 20px;
    min-width: 160px;
    text-align: left;
    border: .5px solid #ffefd7;
    border-radius: 5px;
    box-shadow: 0 0.5px 3px rgba(0, 0, 0, .25);
}

.detail-container a {
    color: #818181
}

.detail-container::after {
    content: "";
    position: absolute;
    top: -11px;
    right: 15px;
    border-style: solid;
    border-width: 0 10px 11px;
    border-color: #FFFFFF transparent;
    display: block;
    width: 0;
    z-index: 1;

}

.detail-container p {
    margin: 0;
    padding: 5px 10px;
    cursor: pointer;
}

.navigate-btn {
    background: #FFEF5A;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #FFEF5A, #FBCE2F);
    /* Chrome 10-25, Safari 5.1-6 */
    /* background: linear-gradient(to bottom, #FFEF5A, #FBCE2F); */
    background: linear-gradient(180deg, #FFEF5A 0%, #FBCE2F 100%);
    box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: none;
    font-size: 14px;
    color: #626262;
}

.menu-item {
    border-radius: 5px;
    padding: 0 3px;
}

.selected-menu {
    background-color: #F0E1E9;
}