.dashboard-quick-look-card-container {
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.dashboard-quick-look-title {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 40px;
    color: #996C84;
    border-bottom: 1px solid #E1E1E1;
}

.dashboard-quick-look-sub-title {
    background: #FFFFFF;
    border-bottom: 1px solid #EEEEEE;
    box-sizing: border-box;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 36px;
    color: #494949;
    align-items: center;
    cursor: pointer;
}

.dashboard-quick-look-sub-title-active {
    border-bottom: 1px solid #EEEEEE;
    box-sizing: border-box;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 36px;
    color: #494949;
    align-items: center;
    background: #FCFCFC;
    border-left: 4px solid#FBCE2F;
    cursor: pointer;
}
.dashboard-tag-scroll::-webkit-scrollbar { 
    display: none; 
}
.total-uploads-circle {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 15%;
    text-align: center;
    color: #88305F;
    padding: 12%;
    border-radius: 165px;
    border: 9px solid #FBCE2F;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}
.total-uploads-text {
    font-family: Muli;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #9F9F9F;
}
.table-info {
    background: #FFFFFF;
    border-radius: 8px 8px 0px 0px;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #434343;
    margin-bottom: 5px;
}