
.total-documents-circle {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    text-align: center;
    color: #88305F;
    padding: 9%;
    border-radius: 180px;
    border: 9px solid #FBCE2F;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); 
    margin: 0 auto;
    width: 210px;
    height: 210px;
}

.total-documents-text {
    font-family: Muli;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    color: #9F9F9F;
}

.new-analysis-clause-block {
    background: #F4F4F4;
    height: 235px;
    margin-bottom: 50px;
    border-radius: 7px;
}

.new-analysis-clause-title {
    background: #FFFFFF;
    box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #88305F;
    font-family: Noto Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    text-align: left;
    padding: 4px 10px 4px 7px;
    display: table;
    position: relative;
    bottom: -8px;
    z-index: 2;
}

.new-analysis-quick-look-card-container {
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    height: 200px;
}

.new-analysis-quick-look-card-container .custom-scrollbar-checkbox {
    border-radius: 8px;
}

.new-analysis-quick-look-title {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 40px;
    color: #996C84;
    border-bottom: 1px solid #E1E1E1;
}

.new-analysis-quick-look-sub-title {
    background: #FFFFFF;
    border-bottom: 1px solid #EEEEEE;
    box-sizing: border-box;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 36px;
    color: #494949;
    align-items: center;
    cursor: pointer;
}

.new-analysis-quick-look-sub-title-active {
    box-sizing: border-box;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 36px;
    color: #494949;
    align-items: center;
    background: #FCFCFC;
    border-top: 2px solid#FBCE2F;
    border-bottom: 2px solid#FBCE2F;
    border-left: 2px solid#FBCE2F;
    border-right: 3px solid #FBCE2F;
    cursor: pointer;
}

.new-analysis-header-container {
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    color: #363641;
}

.new-analysis-header {
    height: 36px;
    padding: 8px;
    font-size: 14px;
    line-height: 20px;
    background: #ffffff;
}
.new-analysis-header:hover{
    background: #C4C4C4;
}

.new-analysis-clear-all{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #88305F;
    text-decoration: underline;
    padding: 1%;
}
.new-analysis-applied-filter-div{
    position: relative;
}
.new-analysis-applied-filter-img{
    width: 55px;
    height: 55px;
    position: absolute;
    left: 117px;
    bottom: -30px;
}

.new-analysis-go-to-top {
    position: fixed;
    cursor: pointer;
    right: 2%;
    top: 85%;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    display: grid;
    text-align: center;
    letter-spacing: 0.02em;
    color: #4d4d4d;
    z-index: 20;
}

.new-analysis-search-bar-header {
    height: 40px;
    padding: 9px;
    font-size: 14px;
    line-height: 20px;
    background: #ffffff;
    border-bottom: 1px solid #EAEAEA;
}

.new-analysis-search-bar-doc-title {
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #424242;
}

.new-analysis-search-button {
    background: linear-gradient(180deg, #FFEF5A, #FBCE2F);
    box-shadow: 1px 2px 10px rgba(6, 1, 48, 0.2);
    border-radius: 5px;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    padding: 7px 23px; 
}
.new-analysis-search-input {
    width: 100%;
    padding: 4px 6px;
    font-size: 12px;
    line-height: 22px;
    font-family: "Muli";
    outline: none;
    border: 1px solid #DDDDDD;
    border-radius: 2px;
    background: 'white'
}

.new-analysis-search-tile {
    border: 1px solid #DFDFDF;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #88305F;
    padding: 5px 6px;
    margin-left: 10px;
}
.new-analysis-save-config {
    background: linear-gradient(180deg, #FFEF5A, #FBCE2F);
    box-shadow: 1px 2px 10px rgba(6, 1, 48, 0.2);
    border-radius: 5px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    padding: 7px 50px;
}

.new-analysis-export-button {
    background: linear-gradient(180deg, rgba(254, 254, 254, 0.95), #FFF8DF);
    border: 1px solid #FBCE2F;
    box-sizing: border-box;
    box-shadow: 1px 2px 8px rgba(6, 1, 48, 0.15);
    border-radius: 2px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    padding: 7px 43px;
    margin-left: 20%;
}

.new-analysis-clear-widget-filter {
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    text-align: left;
    color: #88305F;
    text-decoration: underline;
    padding-left: 5px;
}