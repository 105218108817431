@import "../../Design/color.scss";
@import "../../Draft/Design/draft.scss";

.filter-tags-autocomplete-container {
  max-width: 96%;
}
.filter-tags-autocomplete-container .custom-scrollbar-checkbox {
  padding-right: 0;
}

.hide {
  display: none;
}
.search-modal-input .PrivateNotchedOutline-legendLabelled-5 {
  visibility: visible;
}

.file-name:hover + .hide {
  display: block;
}

.filter-tags-input-suggestion {
  width: 100%;
  padding: 1% 3%;
  border: none;
  outline: none;
  background: white;
  color: #626262;
  font-family: "Inter";
  font-size: 14px;
  line-height: 22px;
}
.filter-tags-input-suggestion:hover {
  background: #fff4cd;
}

.filter-tags-input {
  width: 100%;
  padding: 2% 4%;
  font-size: 12px;
  line-height: 22px;
  font-family: Muli;
  outline: none;
  display: flex;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
}
.filter-shadow-boxing {
  // box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  margin-left: 5.2rem;
}
.filter-tags-container {
  font-family: "Inter";
  font-size: 14px;
  width: inherit;
  line-height: 22px;
  text-align: left;
  z-index: 20;
  position: absolute;
  color: #363641;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
}

.hierarchy-icon-style {
  position: relative;
  bottom: 8px;
}

.hierarchy-line-icon-style {
  height: 50px;
}

.library-header-container {
  background: #ffffff;
  // box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15);
  // padding: 1%;
  font-size: 14px;
  line-height: 22px;
  color: #363641;
  height: 37px;
  // padding: 10px 20px;
  border: 1px solid #f1f1f1;
  align-items: center;
  margin-bottom: 0rem;
}

.filter-info-container {
  background: #ffffff;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15);
  border: 1px solid #f1f1f1;
  align-items: center;
  margin-bottom: 0rem;
  height: 37px;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #363641;
}

.clear-filter-link {
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-decoration: underline;
  color: #88305f;
  cursor: pointer;
}
.library-list-container {
  background: rgba(217, 217, 217, 0.1);
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  border-radius: 0px 10px 0px 0px;
  height: 50vh;
}

.file-name {
  font-family: Inter;
  font-style: italic;
  color: #88305f;
}
.library-file-item {
  //   background: #ffffff;
  border-bottom: 1px solid #f1f1f1;
  box-sizing: border-box;
  //   box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15);
  padding: 10px 15px;
  font-size: 14px;
  line-height: 17px;
  // color: #88305f;
  // display: flex;
  align-items: center;
  cursor: pointer;
  height: 44px;
  white-space: nowrap;
  overflow: hidden;
}

.file-start-date {
  border-bottom: 1px solid #f1f1f1;
  box-sizing: border-box;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 22px;
  color: #4c4c4c;
  // display: flex;
  align-items: center;
  cursor: pointer;
  height: 44px;
  white-space: nowrap;
  overflow: hidden;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
}

.extra-count {
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #88305f;
  // margin-left: 0.5rem;
  // margin-top: 0.5rem;
  margin-bottom: auto;
  cursor: pointer;
}

.file-parties {
  //   background: #ffffff;
  border-bottom: 1px solid #f1f1f1;
  box-sizing: border-box;
  //   box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15);
  padding: 10px 15px;
  font-size: 14px;
  line-height: 22px;
  color: #363641;
  // display: flex;
  align-items: center;
  cursor: pointer;
  height: 44px;
  white-space: nowrap;
  overflow: hidden;
  font-family: Inter;
  font-style: normal;
}

.tag-list-container {
  //   background: #ffffff;
  border-bottom: 1px solid #f1f1f1;
  box-sizing: border-box;
  //   box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15);
  // padding: 10px 15px;
  // font-size: 15px;
  // line-height: 22px;
  // color: #88305f;
  // display: flex;
  align-items: center;
  // cursor: pointer;
  height: 44px;
  white-space: nowrap;
  overflow: hidden;
}

.library-tag-label {
  display: inline-block;
  font-family: Roboto;
  font-size: 14px;
  /* line-height: 22px; */
  padding: 0px 10px;
  border-radius: 2px;
  margin-right: 30px;
  color: white;
  height: 21px;
  min-width: 95px;
  width: 95px;
  text-align: center;
  align-items: center;
  cursor: pointer;
}

.library-tag-list-icn {
  width: 13px;
  height: 12px;
  position: relative;
  bottom: 2px;
}

.filter-tags-input input[type="text"] {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.file-dotted-line {
  border-bottom: 1.5px dotted #808080;
  cursor: pointer;
}

.files-input-suggestion {
  width: 100%;
  padding: 1% 3%;
  border: none;
  outline: none;
  background: white;
  color: #626262;
  font-family: "Inter";
  font-size: 14px;
  line-height: 22px;
}
.files-input-suggestion:hover {
  background: #fff4cd;
}

.empty-filter-img {
  width: 170px;
  height: 170px;
}

.reset-filters-tag {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #88305f;
  margin-top: 30px;
  text-decoration: underline;
}

.empty-filters-message {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #3c3d3b;
  margin-top: 60px;
}

.empty-filter-tag-message {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #3c3d3b;
  margin-top: 40px;
  margin-bottom: 20px;
}

.library-vertical {
  display: block;
  overflow: auto;
  empty-cells: show;
}

.library-vertical::-webkit-scrollbar {
  width: 8px;
  height: 9px;
}
.library-vertical::-webkit-scrollbar:vertical {
  display: none;
}
.library-vertical::-webkit-scrollbar-track {
  background-color: #ffffff;
}
.library-vertical::-webkit-scrollbar-thumb {
  background-color: $color-lilac;
  border-radius: 20px;
}
.library-vertical::-webkit-scrollbar-button {
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
}
.library-vertical::-webkit-scrollbar-button:horizontal:increment {
  background-image: url("../../../public/static_images/horizontal-scroll-arrow-right.svg");
}
.library-vertical::-webkit-scrollbar-button:horizontal:decrement {
  background-image: url("../../../public/static_images/horizontal-scroll-arrow-left.svg");
}

.document-library-sorting-option {
  padding: 7px;
  padding-top: 8px;
  padding-left: 23px;
  margin-left: -1px;
  margin-top: 9px;
}

.document-library-sorting-option:hover {
  background-color: #f5f5f5;
}

.document-library-non-sorting-option {
  padding: 7px;
  padding-top: 8px;
  padding-left: 23px;
  margin-left: -1px;
  margin-top: 9px;
}

.myDocument-pageTitle {
  height: 25px;

  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #4b4b4b;
}

.myDocument-uploadNo {
  width: 36px;
  height: 37px;

  font-family: "Arvo";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 37px;

  color: #88305f;
}

.myDocument-uploadtype {
  height: 12px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #4b4b4b;
}

.myDocument-active {
  // flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 45px;
  font-weight: 900;

  height: 45px;

  background: #88305f;
  color: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 100px;
}

.myDocument-archive {
  height: 17px;

  // margin-top: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  align-items: center;

  color: #797979;
}

.myDocument-deleteFileTitle {
  background: #88305f;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px 8px 0px 0px;
}

.myDocument-deleteFileTitle-content {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  letter-spacing: 0.02em;
  padding: 10px 5px;
  color: #fafafa;
}
.myDocument-deleteFileMessage {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding: 14px;

  /* or 157% */

  color: #626262;
}
.myDocument-deleteFileGoBack {
  background: linear-gradient(
    180deg,
    rgba(254, 254, 254, 0.95) 0%,
    #fff8df 100%
  );
  width: 162px;
  border-radius: 5px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #424242;
  border: 0;
}

.myDocument-deleteFileFiled-tag {
  background: #ff685f;
  border-radius: 5px;
  width: 192px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #f1f7ff;
  border: 0;
}
.delete-modal-btn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 40px;
  margin: auto;
  width: 100%;
  gap: 10px;
}

.addTag-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #88305f;
}

.shareModal-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000;
}

.shareModal-label {
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  color: #49454f !important;
  left: 15px !important;
  top: -5px !important;
}

.addtag-selectBox {
  height: 26px;
  width: 100%;
  border: 1px solid #dddddd;
}
.addtag-selectBox-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #4c4c4c;
}

.addtag-reset {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.02em;

  color: #818181;
  cursor: pointer;
}

.addtag-addHierarchy-D {
  padding: 10px;
  padding-top: 25px;

  width: 557px;
  height: 122px;

  background: #f5f5f5;
  display: flex;
  justify-content: space-between;
}

.addtag-addHierarchy-D1 {
  display: inline-grid;
}
.addtag-addHierarchy-img {
  width: 151px;
  height: 73px;
}

.addtag-addHierarchy-btn {
  padding: 4px 8px;
  width: 59px;
  height: 30px;
  border: 1px solid #88305f;
}

.addtag-addHierarchy .MuiTab-root {
  padding: 0px;
}

.documentmodal_parent .MuiTabPanel-root {
  padding: 0;
}

.addtag-addHierarchy-addbtn {
  /* padding: 4px 8px;

  height: 30px;
  background: linear-gradient(180deg, #ffef5a 0%, #fbce2f 100%);
  box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: none;*/
  border: none;
  padding: 4px 8px;
  background: linear-gradient(180deg, #ffef5a 0%, #fbce2f 100%);
  box-shadow: 1px 2px 10px rgba(6, 1, 48, 0.2);
  border-radius: 5px;
  font-size: 15px;
  font-weight: normal;
  font-family: Roboto;
  line-height: 18px;
  color: #4b4b4b;
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none;
}

.addtag-addHierarchy-addbtn-title {
  // height: 22px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #626262;
}

.supportTableHeader {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  color: #1d1d1d;
}

.update-snackbar .MuiPaper-root.MuiSnackbarContent-root.MuiPaper-elevation6 {
  background-color: #ffffff;
  border: 1px solid #88305f;
}

.add-contart-type {
  height: 28px;

  background: #e9fbff;
  border-radius: 100px;
}

.add-contart-type-name {
  height: 16px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  color: #00668f;
}

.project-Data {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
}
.contract-type-div {
  width: 50px;
}

.contract-type {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 8px;
  gap: 8px;
  width: 67%;
  background: #e9fbff;
  border-radius: 100px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.project-type {
  background: #e9fbff;
  border-radius: 100px;
  padding: 5px 15px;
}
.form-submit-btn {
  background: linear-gradient(180deg, #ffef5a 0%, #fbce2f 100%);
  border: none;
  border-radius: 90%;
  height: 35px;
}

.contracts-tags {
  // height: auto;
  height: 50%;
  width: 50%;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: #e9fbff;
  color: black;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.custom-chip {
  background-color: #e9fbff;
  color: black;
  float: left;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 11px;
  font-weight: bold !important;
}

.MuiSelect-select:focus {
  background-color: transparent !important;
}

.update-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  gap: 6px;
  width: 117px;
  height: 30px;
  left: 487px;
  top: 26px;
  background: linear-gradient(180deg, #ffef5a 0%, #fbce2f 100%);
  box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.muidrawer-custom
  .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight.MuiPaper-elevation16 {
  width: 550px;
  padding: 20px;
}

.checkbox-custom .MuiSvgIcon-root {
  color: #88305f;
}

.parties-custom
  .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation0.MuiPaper-rounded {
  top: 353px !important;
}
// .MuiPopover-root .MuiMenu-paper{
//   top: 580px !important;
// }
.addtag-selectBox option {
  min-width: 40px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.documentlibrary-list-view-table > div {
  margin-top: 5px;
}

.documentlibrary-list-view-container {
  max-height: "480px";
  overflow-y: auto;
}

.documentlibrary-list-view {
  cursor: pointer;
  border-bottom: 1px solid #c6c6c6;
}

.documentlibrary-list-view-header {
  font-family: PT Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #1d1d1d;
  height: 40px;
  padding-left: 14px;
  border-bottom: 1px solid #c6c6c6;
}

.documentlibrary-list-view-header div {
  padding: 9px;
  height: 40px;
}
