.static-content {
  position: fixed;
  z-index: 10;
  width: 100%;
}

#app-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(5px);
}

/* No claims*/

#fall-back-container {
  background-color: white;
  position: absolute;
  width: 40%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  text-align: center;
  border-radius: 25px;
  border: 1px solid #ffefd7;
  padding: 3% 6%;
}

#fall-back-container-mb {
  background-color: white;
  position: absolute;
  width: 90%;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  text-align: center;
  border-radius: 25px;
  border: 1px solid #ffefd7;
  padding: 3% 6%;
}

#fall-back-container-mb #fall-back-heading {
  font-size: 14px;
}

#fall-back-container-mb #fall-back-sub-heading {
  font-size: 16px;
}

#fall-back-container-mb #fall-back-content {
  font-size: 14px;
}

#no-claims-div #intropage-tag-line {
  margin-top: 45%;
}

#fall-back-heading {
  color: black;
  font-size: 20px;
  font-family: "Muli";
  margin-top: -2%;
}

#fall-back-sub-heading {
  font-size: 23px;
  font-weight: bold;
  color: #3d3d3d;
}

#fall-back-content {
  color: #696969;
  font-size: 23px;
}

#fall-back-content a {
  color: black;
}

#goto-product-button {
  text-decoration: underline;
  margin: auto;
  color: #e98b35;
  cursor: pointer;
}

.cover-background {
  height: 100vh;
  background-image: url("../../../public/static_images/landing_background.svg");
  background-size: 100%;
  background-position-y: 12rem;
  background-repeat: no-repeat;
}

.f-16 {
  font-size: 16;
  line-height: 22px;
}

.c-blue {
  color: #2b3c7f;
}

.fw {
  font-weight: 600 !important;
}

.f-14 {
  font-size: 14px;
}

.c-grey {
  color: #818181;
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}

.back-btn {
  cursor: pointer;
}

.align-center {
  text-align: center;
}

.link:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #818181;
}

.c-primary {
  color: #88305f !important;
}

.text-align-end {
  text-align: end;
}

.uppercase {
  text-transform: uppercase;
}

.df {
  display: flex;
}

.create-btn {
  background: #ffef5a;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #ffef5a, #fbce2f);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #ffef5a, #fbce2f);
  border: none;
  font-size: 14px;
  color: #626262 !important;
}

.riverus-dialog-header {
  background-color: #88305f;
  color: white;
  padding: 8px 24px !important;
}

.riverus-dialog-header__title {
  font-size: 18px;
}

.riverus-text-box {
  width: 238px !important;
}

.riverus-field-title {
  color: #88305f !important;
  font-weight: 600 !important;
}

.background-primary {
  background-color: #88305f;
  color: white;
}

.stylus_section_header {
  font-size: 18px;
  font-weight: 400;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #FFEEF7;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #FFEEF7;
}

.MuiListItemText-root {
  white-space: pre-wrap !important;
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .stylus_section_header {
    margin-left: 10px;
  }
}
