.tasks-subtitle {
  font-family: "Muli";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  display: flex;
  align-items: center;
  color: #525252;
}

#tasks-tab-container {
  text-align: center;
}

.default-tasks-tab-label {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
  color: #797979;
  cursor: pointer;
}

.active-tasks-tab-label {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 40px;
  color: #88305f;
  border-bottom: 4px solid;
  cursor: pointer;
}

.active-filter-button {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #262626;
  margin: 10px;
  border: none;
  border-radius: 13.5px;
  background-color: #ffedad;
  padding: 3% 10%;
  cursor: pointer;
}

.all-tasks-button {
  margin: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #797979;
  margin-top: 14px;
  cursor: pointer;
}

.all-tasks-count {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #797979;
}

.my-tasks-link {
  margin: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #797979;
  margin: 10px;
  cursor: pointer;
  padding: 3% 10%;
}

.list-view-header {
  font-family: PT Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #1d1d1d;
  height: 40px;
  padding-left: 14px;
}


.list-view-header div {
  padding: 9px;
  height: 40px;
  padding-left: 2rem;
}

.list-view-table {
  //   padding-top: 10px;
  //   padding-bottom: 10px;
  padding: 5px 0px;
  margin-right: 10px;
}

.list-view-table #task-name {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: #626262;
  text-align: left;
  margin: auto;
  left: 1.7vw;
}

.list-view-table #contract-name {
  font-family: Mulish;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #88305f;
  text-decoration: underline;
  margin: auto;
  // left: 0.2vw;
}

.list-view-table #clause-name {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #6d6d6d;
  margin: auto;
  left: 1.3vw;

}

.list-view-table #assigned-to {
  display: inline-flex;
}

.list-view-table #date {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #6d6d6d;
  margin: auto;
  
}

.list-view-table #status {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #6d6d6d;
  margin: auto;
}

.list-view-table #progress {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #6d6d6d;
  margin: auto;
  //right: 0.3vw;
  cursor: pointer;
}

.list-view-table #extra-count {
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #88305f;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: auto;
  cursor: pointer;
}

#progress-options-container {
  margin-top: auto;
  position: absolute;
  z-index: 2;
  width: 120px;
  //box-shadow: 0px 0.5px 3px rgba(0, 0, 0, 0.25);
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
}

#progress-options-container p {
  //background: white;
  //border: 1px solid #efefef;
  margin-bottom: 0px;
  padding: 10px 8px 10px;
  // opacity: 0.6;
  transition: 0.3s;
  cursor: pointer;
}
#progress-options-container p:hover {
  background-color: #fff5d1;
}

@mixin avatar($size) {
  width: $size;
  height: $size;
  background-color: #df0d00;
  text-align: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;

  .initials {
    position: relative;
    // top: $size * 0.1;
    top: 5px;
    font-size: $size * 0.4;
    line-height: $size * 0.7;
    color: #fff;
    font-family: Roboto;
    font-weight: normal;
  }
}

.avatar-circle {
  @include avatar(100px);
}
.avatar-circle-sm {
  @include avatar(35px);
}
.avatar-circle-lg {
  @include avatar(250px);
}

#postedcomments-container {
  background: #F4F4F4;
}

#postedcomments-container .user-name{
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  color: #6D6D6D;
}

#postedcomments-container .timestamp{
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  align-items: center;
  color: #878787;
  text-align: end;
}

#postedcomments-container .comment{
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  color: #363641;
}

.sorting-option:hover {
  background-color: #F5F5F5;
  cursor: pointer;
}