
.date-slider-block {
    background: #FFFFFF;
    height: 120px;
    margin-bottom: 30px;
    color: #88305F;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding-bottom: 10px;
}


.date-slider-title {
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #996C84;
    padding: 2px 0px;
}

.amount-slider-title {
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #996C84;
    padding: 2px 0px;
}
