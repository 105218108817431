

.folder-heading-text {
    color: #88305F;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    text-align: left;
    border-bottom: 3px solid #88305F;
}

.clause-library-heading-row {
    border-top: 1px solid #E2E2E2;
    border-bottom: 1px solid #E2E2E2;
    height: 35px;
}

.clause-folder-view {
    box-shadow: 0px 2px 20px rgba(135, 135, 135, 0.1);
    height: 75vh;
}

.clause-folder-heading-text {
    font-family: Inter;
    font-size: 17px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #1A1A1A;
    padding: 5px 10px;
}

.clause-folder-heading-row {
    border-bottom: 1px solid #E2E2E2;
    height: 35px;
}

.clause-library-header-test {
    font-family: Inter;
    font-size: 15px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    color: #434343;
    padding: 5px 10px;
}

.clause-library-header-section {
    height: 70px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #434343;
    padding: 20px 10px;
}

.add-folder-heading {
    font-family: Mulish;
    font-size: 15px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    color: #88305F;
}

.add-folder-heading-inactive {
    font-family: Mulish;
    font-size: 15px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    color: #CECECE;
}

.clause-folder-list-row {
    height: 35px;
    cursor: pointer;
}

.clause-library-row :hover {
    background: #F5F5F5;
    z-index: 2;
}

.clause-folder-row {
    border-top: 1px solid #E2E2E2;
    border-bottom: 1px solid #E2E2E2;
}

.clause-folder-text {
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #4E4E4E;
}

.clause-expand-icn {
    width: 12px;
}

.clause-folder-icn {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.clause-icn {
    width: 13px;
    height: 15px;
}

.clause-add-folder-icn {
    width: 16px;
}

.clause-copy-icn {
    width: 16px;
}

.clause-column-padding {
    padding: 5px 10px;
} 


.folder-input {
  width: 150px;
  padding: 1px 5px;
  border: 1px solid #dddddd;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter";
  outline: none;
  display: flex;
  letter-spacing: 0.01em;
  margin: 2px 2px;
  border-radius: 2px;
}

.add-folder-name-text {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    padding: 5px 7px;
}

.create-folder-yellow-btn {
    border: none;
    background: linear-gradient(180deg, #FFEF5A 0%, #FBCE2F 100%);
    box-shadow: 1px 2px 10px rgba(6, 1, 48, 0.2);
    border-radius: 5px;
    font-size: 15px;
    font-weight: normal;
    font-family: Roboto;
    color: #4B4B4B;
    align-items: center;
    cursor: pointer;
    outline: none;
    padding: 4px 35px;
    display: inline-flex;
    line-height: 22px;
    margin: 1px;
}
.create-folder-yellow-btn:active {
    outline: none;
}
.text-white {
    color: white;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
}

.arrow-left {
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 8px solid #2A2A2A;
    position: absolute;
    left: -8px;
    top: 8px;
}

.custom-div-add-clause-library {
    position: absolute;
    width: 265px;
    height: 33px;
    background: #2A2A2A;
    border: 2px solid #FBCE2F;
    box-sizing: border-box;
    border-radius: 5px;
    top: 3px;
    z-index: 17;
    padding: 2px 8px;
}


.yellow-link {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-decoration: underline;
    text-align: left;
    color: #FBCE2F;
}

.clause-type-grey {
    color: #737373;
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
}
.clause-library-file-text {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
    color: #88305F;
}


// Section CSS
.section-label{
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    color: #6D6D6D;
    font-size: 14px;
}


.section-tag{
  height: auto;
  width: 50%;
  padding: 5px;
  background-color: #D8F7E6;
  color: #00692A;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}


.section-modal{
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: auto;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 9;
}

.section-modal label{
    font-size: 14px;
    font-weight: 600;
    color: #88305F;
}

.create-section-modal{
    height: 'auto';
    width: 40%;
    position: relative;
    background-color: white;
    border-radius: 15px;
    box-shadow: 8px 8px 29px #B2B2B2;
}

.similar-section-modal{
    height: 'auto';
    width: 19%;
    position: relative;
    background-color: white;
    border-radius: 15px;
}
.select-section-modal{
    height: auto;
    width: auto;
    position: relative;
    background-color: white;
    border-radius: 15px;
}

.modal-header{
    background-color: #88305F;
    height: auto;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    display: flex;
    color: white;
}
.modal-header-2{
    background-color: #88305F;
    height: 50px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    display: flex;
    color: white;
}
.modal-header-2 img{
    height: 20px;
}

.modal-header img{
    height: 20px;
}

.section-modal-upload{
    border: 1px solid #DDDDDD;
    height: 30px;
    padding: 3px;
    display: flex;
    justify-content: space-between;
}
.section-modal-upload:focus-visible{
    border: none !important;
    padding-left: 5px;
}

.section-add-clause{
    border: none;
    padding: 1.3% 6%;
    background: linear-gradient(180deg, #FFEF5A 0%, #FBCE2F 100%);
    box-shadow: 1px 2px 10px rgba(6, 1, 48, 0.2);
    border-radius: 5px;
    font-size: 15px;
    font-weight: normal;
    font-family: Roboto;
    line-height: 18px;
    color: #4B4B4B;
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: none;
}

.section-created-by{
    color: #88305F;
    // padding: 21px;
    background-color: #FFEEF7;
    border-radius: 100%;
    height: 45px;
    font-size: 12px;
    padding: 5px;
    font-weight: 600;
    width: 18%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid purple;
}

.sub-section-list-view{
    color: #88305F;
    font-size: 14px;
}


.custom-class{
    justify-content: center;
    align-items: center;
}

::-webkit-input-placeholder { 
    padding: 5px;
}

.section-accordian-parent.MuiPaper-root.MuiAccordion-root.Mui-expanded.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded{
    margin: 0px !important;
    box-shadow: none !important;
}

.section-accordian-parent.MuiPaper-elevation1{
    box-shadow: none !important;
}

.section-checkbox{
    padding: 0px;
}

.section-accordian{
    border-top: none !important;
    box-shadow: none !important;
}

.MuiPaper-root.MuiSnackbarContent-root.MuiPaper-elevation6{
    background-color: #F0E1E9;
    color: #88305F;
}


@media screen and (min-width: 1024px) and (max-width:1150px) {
    .create-section-modal{
        width: 75%;
    }
    .section-created-by{
        width: 35%;
    }
  }

  @media screen and (min-width: 1150px) and (max-width:1300px) {
    .create-section-modal{
        width: 75%;
    }
    .section-created-by{
        width: 28%;
    }
  }

  @media screen and (min-width: 1300px) and (max-width:1500px) {
    .create-section-modal{
        width: 75%;
    }
    .section-created-by{
        width: 25%;
    }
  }